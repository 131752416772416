/* AudioPlayer.css */

.audio-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-audio {
    /* Customize the audio player itself */
    max-width: 280px;
    border-radius: 15px;
    height: 2.3rem;
  }
  
  .custom-audio::-webkit-media-controls-panel {
    /* Customize the controls for webkit browsers (Chrome, Safari) */
    background-color: white;
    border: 4px solid gray;;
    border-radius: 15px;
    color: white;
  }
  
  .custom-audio::-webkit-media-controls-play-button {
    /* Customize the play button */
    background-color: transparent;
    color: white;
  }
  
  /* Add similar rules for other controls as needed */
  