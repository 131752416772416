@font-face {
  font-family: GraphikMedium;
  src: url(./Graphik-Font-Family/GraphikMedium.otf);
}
@font-face {
  font-family: GraphikRegular ;
  src: url(./Graphik-Font-Family/GraphikRegular.otf);
}
@font-face {
  font-family: Graphiksemibold;
  src: url(./Graphik-Font-Family/GraphikSemibold.otf);
}
@font-face {
font-family: GraphikThin;
src: url(./Graphik-Font-Family/GraphikThin.otf);
}

.regular{
  font-family: 'GraphikRegular';
}
.medium{
  font-family: 'GraphikMedium';
}